import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { googleMapsApiKey } from '../constants/keys.constants';
import VideoThumbnail from './VideoThumbnail';
import style from './MapView.module.scss';
import { baseMediaUrl } from '../constants/endpoints.constants';
import { getCategoryImage } from '../utils/categories';
import { Image } from 'react-bootstrap';

const Marker = props => (
    <div className={style.clickableMarker}>
        <Image className={style.categoryImage} src={getCategoryImage(props.story.storyPrimaryCategory)} />
    </div>
);

const MapView = props => {
    const [popupEnabled, setPopupEnabled] = useState(false);
    const [selectedStory, setSelectedStory] = useState({});

    const handleStoryClick = (key, props) => {
        setSelectedStory(props.story);
        setPopupEnabled(true);
    };

    return (
        <>
            <h1 className={style.mapCaption}>
                Watch these snippets of stories and download the app to view the full story.
            </h1>
            <div style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: googleMapsApiKey }}
                    center={{ lat: -34.4248191, lng: 150.8916268 }}
                    zoom={11}
                    onChildClick={handleStoryClick}
                >
                    {props.storyStatus.isSuccess && props.storyData.data.map((story, id) => <Marker
                        key={id}
                        lat={story.storyLatitude}
                        lng={story.storyLongitude}
                        story={story}
                    />)}
                </GoogleMapReact>
                <div className={style.hoveringVideo}>
                    {popupEnabled && <VideoThumbnail
                        closeMapPopup={() => setPopupEnabled(false)}
                        borderEnabled={true}
                        thumbnailAddress={`${baseMediaUrl}/${selectedStory.thumbnailMedia.objectKey}`}
                        videoAddress={`${baseMediaUrl}/${selectedStory.shortStoryMedia.objectKey}`}
                        videoName={selectedStory.storyName}
                        videoDescription={selectedStory.storyDescription}
                        shareLink={`${baseMediaUrl}/pages/${selectedStory.storyStaticSharingId}.html`}
                    />}
                </div>
            </div>
        </>
    );
};
export default MapView;
