import React from "react"
import Style from "./Login.module.scss"
import logo from "../imgs/YS-Logo-01.svg";

function Login(props) {


    return (
        <>
            <img src={logo} alt="" className={Style.logo} />
            <h3>Log in</h3><br/>
            <form onSubmit={props.login} className={Style.login}>
                <input name="email" type="email" placeholder="Email" required/>
                <br/>
                <input name="password" type="password" placeholder="Password" required/>
                
                <br/>
                <div></div>
                <button type="submit" className={Style.button}>Login</button>
                <br/>
                <h6 onClick={props.change} className={Style.option}>Register &#62;</h6>
                <br/>
            </form>
        </>
    );
}
export default Login;
