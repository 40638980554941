import React from "react";
import { useState } from "react";
import Style from "./Login.module.scss";
import adminStyle from "./Admin.module.scss";
import how from '../imgs/howUpload.jpg';

function Admin(props) {
    const[toggle, settoggle] = useState(true)




    return (
        <div className={Style.background}>
            <div className={adminStyle.header}>
                <button className={adminStyle.button} onClick={()=>settoggle(!toggle)}>{toggle?"Learn how to Upload":"< Back to Upload"}</button>
                <div className={adminStyle.close} onClick={()=>props.setAdmin(false)}>X</div>
            </div>

            {toggle ? 
                <iframe className={adminStyle.iframe} src="https://admin.yesterdaystories.net.au/" title="Admin Page" frameborder="0">

                </iframe>
            
            : 
                
                <div className={adminStyle.scroll}>
                    <img src={how} alt="" className={adminStyle.img}/>
                </div>
            }
        </div>
    );
}
export default Admin;
