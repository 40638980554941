const TERMS_AND_CONDITIONS_URL =
    'https://media.yesterdaystories.net.au/docs/terms-and-conditions.pdf';

// Privacy policy url. Subject to change (Hardcoded for now).
const PRIVACY_POLICY_URL =
    'https://media.yesterdaystories.net.au/docs/privacy-policy.pdf';

const PATREON_URL = 'https://www.patreon.com/yesterdaystories'

export {
    TERMS_AND_CONDITIONS_URL,
    PRIVACY_POLICY_URL,
    PATREON_URL,
}