import React from "react";
import { useState } from "react";
import Style from "./Advertise.module.scss"
import ad from "../imgs/YourAdHere.png"
import store from "../constants/store.constants"
import GooglePlay from "../imgs/GooglePlay.PNG"
import Appstore from "../imgs/Appstore.PNG"
import qr from "../imgs/QR-Code-1.png"  
import lightHouse from "../imgs/Wollongong_Breakwater_Lighthouse_Website.jpg" 

//video pop up
import VideoThumbnail from "../components/VideoThumbnail";
const VIDEO_LINK = "https://media.yesterdaystories.net.au/prod/ea16a3c3-6037-465d-b608-670115b65131.mp4";
const VIDEO_NAME = "Lighthouse Story";
const VIDEO_DESCRIPTION = "";
const VIDEO_SHARE_LINK = "https://media.yesterdaystories.net.au/pages/312f0e60-f824-11ea-9eed-bd8de8ddac55.html";
const VIDEO_THUMBNAIL = null;

function Advertise() {

    const [popupEnabled, setPopupEnabled] = useState(false)

    const handleStoryClick = () => {
      setPopupEnabled(true)
    }

    return (
        <div id="advertise" className={Style.main}>
            <div className={Style.container}>
                <div className={Style.container2}>
                    <h4>Advertise with us </h4>
                    <p>Tourists are watching video stories near your business, why not let them know where you are? For as little as $1.99 a month, you can advertise on the stories closest to you.</p>
                    <h3>Simply download the app for free here: </h3>
                    <div className={Style.appBox}>
                        <div className={Style.app}>
                            <a target="_blank" rel="noopener noreferrer" href={store.googlePlayStore}>
                                <img className={Style.imgApp} src={GooglePlay} alt="" />
                            </a>

                            <a  target="_blank" rel="noopener noreferrer" href={store.appStore}>
                                <img className={Style.imgApp} src={Appstore} alt="" />
                            </a>
                        </div>
                        <img src={qr} alt="" className={Style.img}/>

                    </div>
                    <p>It’s easy! In the app, simply click on the story page you wish to advertise on and register to advertise, or click on the button below.</p>

                </div>
                <img onClick={handleStoryClick} className={Style.phone} src={ad} alt="" style={{cursor:"pointer"}}/>

            </div>
                    
                        {popupEnabled && <div className="topHoveringVideo" style={{zIndex:"10000"}}>
                            <VideoThumbnail
                            closeMapPopup={() => setPopupEnabled(false)}
                            borderEnabled={true}
                            thumbnailAddress={VIDEO_THUMBNAIL}
                            videoAddress={VIDEO_LINK}
                            videoName={VIDEO_NAME}
                            videoDescription={VIDEO_DESCRIPTION}
                            shareLink={VIDEO_SHARE_LINK}
                            />
                        </div>}
                    
            <img style={{width:"50vw"}} src={lightHouse} alt="" />
            {/*<button className={Style.button}><h3>Click here to advertise</h3></button>*/}
        </div>
    );
}

export default Advertise;