import React, { useEffect, useState } from 'react';
import HomeSectionOne1 from './HomeSectionOne1'
import HomeSectionOne2 from './HomeSectionOne2'
import HomeSectionOne3 from './HomeSectionOne3'
import HomeSectionOne4 from './HomeSectionOne4'
import Testimonial from './Testimonial';
import Ourpartners from './Ourpartners';
import MapView from './components/MapView';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import endpoints from "./constants/endpoints.constants";
import { genericRequest } from "./utils/request";
function About({user, setShow, setAdmin}) {

    const [locationData, setLocationData] = useState({})
    const [requestStatus, setRequestStatus] = useState({
        isError: false,
        isLoading: false,
        isSuccess: false
    });

    useEffect(() => {
        async function getPageData() {
            setRequestStatus({ isLoading: true });
            const request = await genericRequest({ endpoint: endpoints.listStory });

            if (request.response) {
                setLocationData({ data: request.response });
                return setRequestStatus({ isSuccess: true });
            }

            return setRequestStatus({ isError: request.error });
        }

        getPageData().then()

        // setFacebookFeedWidth(ref.current.offsetWidth)
    }, [setRequestStatus, setLocationData]);

    return (
        <div className="App">
            <HomeSectionOne1 />
            <HomeSectionOne2 
                user={user}
                setShow={setShow}
                setAdmin={setAdmin}
            />
            <MapView storyData={locationData} storyStatus={requestStatus} />
            <Testimonial />
            <Ourpartners />
            <HomeSectionOne3 />
            <HomeSectionOne4 />
        </div>
    );
}

export default About;
