import React from "react"
import Style from "./Heritage.module.scss"
import appPic from "../imgs/Harpers-Mansion-Berrima-Cafe.png"
import map from "../imgs/Trail North Bondi v1 Website.jpg"

const Heritage = () => (
    <div id="heritage">
        <div className={Style.container}>
            <img src={appPic} alt=""  className={Style.app}/>
            <div className={Style.text}>
                <p>If you are an organisation or council, why not do away with paper heritage trails and let it be digital? </p>
                <p>And if you were already contemplating going digital, don’t go to the expense of producing your own app!</p>
                <button className={Style.emailButton}><a href= "mailto:info@yesterdaystories.com.au?subject=Yesterday stories App" rel="noreferrer noopener" target="_blank">Contact us</a></button>

            </div>
        </div>
        <div className={Style.container2}>
            <img src={map} alt=""  className={Style.img}/>
        </div>
        


    </div>
)

export default Heritage;