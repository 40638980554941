import React, { useState } from 'react';
import style from "./VideoThumbnail.module.scss"
import { Col, Row } from "react-bootstrap";
import storeListings from "../constants/store.constants"

const VideoThumbnail = props => {

    const [videoFinished, setVideoFinished] = useState(false);
    return (
        <Row>
            <Col className={style.borderEnabled}>
                    {props.borderEnabled && <>
                        <div className={style.header}>
                            <div className={style.shareButton}>
                                <a rel="noopener noreferrer" target="_blank" className={style.shareButtonLink}
                                    href={props.shareLink}>Share</a>
                            </div>
                            <div onClick={props.closeMapPopup} className={style.closeModal}><h6>x</h6></div>
                        </div>
                    </>
                    }
                <Row noGutters={true}>
                    <div className={style.outerContainer}>
                        <div className={style.innerContainer}>
                            <div className={videoFinished ? style.videoOverlay : style.videoOverlayHidden}>
                                <h3>Download Yesterday Stories now on iOS and Android</h3>
                                <div className={style.imageContainer}>
                                    <a target={"_blank"} rel="noopener noreferrer" href={storeListings.appStore}>
                                        <img alt={"Appstore"} src={"images/logo/app-store.svg"} height="60px" />
                                    </a>
                                </div>
                                <div className={style.imageContainer}>
                                    <a target={"_blank"} rel="noopener noreferrer" href={storeListings.googlePlayStore}>
                                        <img alt={"Google Play"} src={"images/logo/google-play.png"} height="60px" />
                                    </a>
                                </div>
                            </div>
                            <React.Fragment key={props.videoAddress}>
                                <video onEnded={() => setVideoFinished(true)}
                                    onPlay={() => setVideoFinished(false)}
                                    className={props.large ? style.videoContainerLarge : style.videoContainer}
                                    controls={true}
                                    width="100%" height="100%"
                                    //poster={props.thumbnailAddress}
                                    controlsList="nodownload"
                                    onContextMenu={(e) => e.preventDefault()}
                                    autoPlay
                                >
                                    <source src={props.videoAddress}
                                        type="video/mp4"
                                    />
                                </video>
                            </React.Fragment>
                        </div>
                    </div>
                </Row>
                {!props.infoDisabled && <>
                    <div className={style.thumbnailHeader} noGutters={true}>
                        <h6 className={style.headerText}> {props.videoName}</h6>
                    </div>
                    {props.videoDescription!=="" &&
                        <Row noGutters={true}>
                            <span className={style.thumbnailText}>{props.videoDescription}</span>
                        </Row>
                    }
                </>}
            </Col>
        </Row>
    );
};

export default VideoThumbnail;