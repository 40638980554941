import React from "react";
import { useState } from "react";
import adminStyle from "./Admin.module.scss";
import Style from "./Login.module.scss";
import Advertise from "./Advertise"


function AdFrame(props) {
    const[toggle, settoggle] = useState(true)

    return (
        <div className={Style.background}  >
            <br/>
            <div className={adminStyle.header}>
                <button className={adminStyle.button} onClick={()=>settoggle(!toggle)}>{toggle?"Learn about Advertising":"< Back to Advertise"}</button>
                <div className={adminStyle.close} onClick={()=>props.setFrame(false)}>X</div>
            </div>
            {toggle ? 
                <iframe className={adminStyle.iframe} src="https://admin.yesterdaystories.net.au/" title="Admin Page" frameborder="0">

                </iframe>
            
            : 
                
                <div className={adminStyle.scroll}>
                    <Advertise />
                </div>
            }
        </div>
    );
}
export default AdFrame;
