const categoryImages = [
    '/images/category/sites_and_landmarks/icon_category_map_sites_and_landmarks.png',
    '/images/category/all_stories/icon_category_map_all_stories.png',
    '/images/category/our_communities/icon_category_map_our_communities.png',
    '/images/category/major_events/icon_category_map_major_events.png',
    '/images/category/personal_stories/icon_category_map_personal_stories.png',
    '/images/category/indigenous/icon_category_map_indigenous.png',
    '/images/category/wartime/icon_category_map_wartime.png',
    '/images/category/paranormal/icon_category_map_paranormal.png',
    '/images/category/industrial/icon_category_map_industrial.png',
    '/images/category/arts_and_monuments/icon_category_map_arts_and_monuments.png',
    '/images/category/crime/icon_category_map_crime.png',
    '/images/category/colonial/icon_category_map_colonial.png',
    '/images/category/environment/icon_category_map_environment.png',
    '/images/category/political/icon_category_map_political.png',
    '/images/category/museums_and_galleries/icon_category_map_museums_and_galleries.png',
];

const categoryNames = [
    'Sites and Landmarks',
    'All Stories',
    'Our Communities',
    'Major Events',
    'Personal Stories',
    'Indigenous',
    'Military',
    'Paranormal',
    'Industrial',
    'Arts and Monuments',
    'Crime',
    'Colonial',
    'Environment',
    'Political',
    'Museums and Galleries',
];

export {
    categoryImages,
    categoryNames,
}