import React from "react";
import { useState } from "react";

// Below are imported images
import lovestories_Remastered from "./imgs/LoveStories Remastered.png";
import GrandmaOrangeFlowers from "./imgs/Grandma-Orange-Flowers.png";
import WHY05027 from "./imgs/Mungo-Ranger-trans.png";
import Arts from "./imgs/Arts.png"
import { Container, Row, Col } from "react-bootstrap";
import twitterLogo from './imgs/twitter-logo.png'
import facebookLogo from './imgs/facebook-logo.png'
import instagramLogo from './imgs/instagram-logo.png'
import deviceType from './utils/deviceType'
import style from './HomeSectionOne3.module.scss';

//video pop up
import VideoThumbnail from "./components/VideoThumbnail";
const VIDEO_LINK = "https://media.yesterdaystories.net.au/prod/c02d19fe-4cb0-482f-9cd1-bfa54ae72c56.mp4";
const VIDEO_NAME = "Mungo";
const VIDEO_DESCRIPTION = "";
const VIDEO_SHARE_LINK = "https://media.yesterdaystories.net.au/pages/a990c8e0-6442-11ec-b79d-3d0fb79f0586.html";


function HomeSectionOne3() {
  let isMobile = deviceType();

  const [popupEnabled, setPopupEnabled] = useState(false);
  
  return (
    <div className="HomeSectionOne3">
      <Container>
        <Row lg={2} sm={2} className="d-flex align-items-center">
          <img
            className={style.lovestories_Remastered}
            src={lovestories_Remastered}
            alt=""
          />
          <Col lg={6} sm={12} md={12} className={style.hatPersonWrapper}>
            
            <div>
              <img onClick={()=> setPopupEnabled(true)} className={style.WHY05027} src={WHY05027} alt="" style={{cursor: "pointer"}} />
              {popupEnabled && <div style={{position: "Absolute",left: "0", top: "0", zIndex:"10000"}}>

                <VideoThumbnail
                    closeMapPopup={() => setPopupEnabled(false)}
                    borderEnabled={true}
                    videoAddress={VIDEO_LINK}
                    videoName={VIDEO_NAME}
                    videoDescription={VIDEO_DESCRIPTION}
                    shareLink={VIDEO_SHARE_LINK}
                    />
                  
              </div>
              }
          </div>
          </Col>
          <Col lg={6} sm={12} md={12}>
            <div className={style.acknowledgement}>
              <h2 className={style.heading}>
                Acknowledgement of Custodianship{" "}
              </h2>
              <br />
              <p className={style.content}>
                We are honoured to be on and have created this platform the
                ancestral lands of the Wodi Wodi people of the Dharawal Nation.
              </p>
              <p className={style.content}>
                We acknowledge the First Australians as the traditional custodians of
                the continent, whose cultures are among the oldest living cultures
                in human history and we pay respect to the Elders in the
                communities in which we work.
              </p>
            </div>
          </Col>
        </Row>

        <Row style={isMobile ? { marginTop: "3vh" } : { marginTop: "0" }}>

          <Col lg={6} sm={5} xs={6}>
            <div className={style.socialLogos}>
              <h1 className={style.followUs}>Follow us on<br /> the socials</h1>
              <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Yesterday-Stories-1653652251525934"><img
                src={facebookLogo}
                alt="facebook-logo"
              /></a>
              <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/YesterStories"><img
                src={twitterLogo}
                alt="twitter-logo"
              /></a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/yesterdaystories/">
                <img
                  src={instagramLogo}
                  alt="instagram-logo"
                /></a>
            </div>
          </Col>

          <Col lg={6} sm={7} xs={6}>
            <img
              className={style.grandmaOrangeFlowers}
              src={GrandmaOrangeFlowers}
              alt=""
            />
          </Col>
        </Row>
        <img
          className={style.imgArts}
          src={Arts}
          alt=""
        />
      </Container>
    </div>
  );
}
export default HomeSectionOne3;
