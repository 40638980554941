import React from "react"
import trail from "../imgs/AppIconsOnTrailLine.png"
import Style from './Product.module.scss'
import store from "../constants/store.constants"
import GooglePlay from "../imgs/GooglePlay.PNG"
import Appstore from "../imgs/Appstore.PNG"
import qr from "../imgs/QR-Code-1.png"

const Product = () => (

    <div className={Style.container}>
        <div id="product" className={Style.link}>&nbsp;</div>
        <h4 className={Style.heading}>Features of the Yesterday Stories app </h4>
        <p className={Style.text}>The Yesterday Stories app offers a wide range of features. You can not only watch video histories, you can also participate in a number of ways – you can share your own short video stories, advertise your business.</p>
        <img style={{width:"100vw"}} src={trail} alt="" />
        <h5 className={Style.heading}>Get Started!</h5>
        <p className={Style.text}>Simply download the app for free and watch video histories to learn about the fascinating history of your local area and the regions you travel to. You can download the app for free via Google Play, the App Store, or simply scan this QR code with your smartphone.</p>
        <div className={Style.appBox}>

            <div className={Style.box}>
                <h4 className={Style.heading}>Download the Yesterday Stories app here:</h4>
                <a target="_blank" rel="noopener noreferrer" href={store.googlePlayStore}>
                    <img className={Style.app} src={GooglePlay} alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href={store.appStore}>
                    <img className={Style.app} src={Appstore} alt="" />
                </a>

            </div>
            <h3 className={Style.heading}>or</h3>
            <div className={Style.box}>
                <h4 className={Style.heading}>Scan this QR code with your smart phone:</h4>
                <img className={Style.qr} src={qr} alt="" />
            </div>

        </div>
    </div>

)

export default Product;