import React,{useState} from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './About';
import Faq from './pages/Faq';
import Media from './pages/Media';
import Education from './pages/Education';
import Product from './pages/Product';
import Heritage from './pages/Heritage';
// import FilmComp from './pages/FilmComp';
import NavHeader from './NavHeader';
import Footer from './EndFooter';
import ScrollToTop from './components/ScrollToTop';
import Login from './pages/AuthPage'; 


const App = () => {
  const [show, setShow] = useState("none");
  const [admin, setAdmin] = useState(false);
  const [user, setUser] = useState(localStorage.getItem("auth") ? true : false);



  return(
    <Router>
    <NavHeader 
      show={show}
      setShow={setShow}
      admin={admin}
      setAdmin={setAdmin}
      user={user}
      setUser={setUser}
    />
    <Routes>
      <Route exact path="/" element={<About
       user={user}
       setShow={setShow}
       setAdmin={setAdmin}
       />} />
    </Routes>
    <ScrollToTop />
    {/* <FilmComp /> */}
    <Media />
    <Education />
    <Product />
    <Heritage />
    <Faq />
    <Login setShow={setShow} show={show} admin={admin} setAdmin={setAdmin} user={user} setUser={setUser}/>
    <Footer />
  </Router>
  )
};

export default App;