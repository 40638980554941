import requestConstants from '../constants/requests.constants';

const DOMAIN = requestConstants.apiBaseDomain;

const genericRequest = async ({ endpoint }) => {
    try {
        let response = await fetch(`${DOMAIN}/${endpoint}`)
        let jsonResponse = await response.json()
        if (jsonResponse.hasOwnProperty('errorMessage')) {
            return { response: false, error: response.errorMessage };
        }

        return { response: jsonResponse, error: false };
    } catch (error) {
        return { response: false, error: error };
    }
};

export {
    genericRequest,
}