import React from "react"
import faq from './Faq.module.scss'
import cameraman from '../imgs/cameraman.jpg'
import handScreen from '../imgs/app-lighthouseStory-hand-screen.jpg'
import faqItems from '../constants/faqs.constants'

const Faq = () => (
    <div className={faq.container}>
        <div id="faq" className={faq.link}>&nbsp;</div>
        <h1 id="faq" className={faq.heading} style={{ textAlign: 'center' }}>Frequently Asked Questions</h1>
        <figure className={faq.cameramanFig}>
            <img className={faq.cameraman} src={cameraman} alt="cameraman" />
            <figcaption className={faq.cameramanCaption}>Uploading your history-focused video stories to the Yesterday Stories app is free!</figcaption>
        </figure>
        <div className={faq.faqs}>
            {faqItems.map((item, index) => (
                <div key={index}>
                    <h2 className={faq.questions}>{item.question}</h2>
                    <p className={faq.answers}>{item.answer}</p>
                </div>
            ))}
        </div>
        <button className={faq.downloadButton}><a href="https://www.screenaustralia.gov.au/getmedia/16e5ade3-bbca-4db2-a433-94bcd4c45434/Pathways-and-Protocols.pdf" rel="noreferrer noopener" target="_blank">Download protocols here</a></button>
        <figure className={faq.handScreenFig}>
            <img className={faq.handScreen} src={handScreen} alt="phone showing yesterday stories app" />
        </figure>
    </div>
)
export default Faq;