import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styles from './VideoCarousel.module.scss';

const CustomLeftArrow = ({ onClick }) => (
    <button className={`${styles['carousel-arrow']} ${styles['carousel-arrow--left']}`} onClick={onClick}>
        ‹
    </button>
);

const CustomRightArrow = ({ onClick }) => (
    <button className={`${styles['carousel-arrow']} ${styles['carousel-arrow--right']}`} onClick={onClick}>
        ›
    </button>
);

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 4,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const VideoCarousel = () => {
    const videos = [
        {
            url: 'https://media.yesterdaystories.net.au/site/1_old_goulburn_brewery_uploaded.mp4',
            caption: 'Old Goulburn Brewery: The women were the maltsters as they could sense the change in the weather, humidity and were more delicate with the barley.',
        },
        {
            url: 'https://media.yesterdaystories.net.au/site/2_harper’s_mansion_berrima_uploaded.mp4',
            caption: 'Berrima Harper’s Mansion: It’s always good to know about the people who built the buildings.',
        },
        {
            url: 'https://media.yesterdaystories.net.au/site/3_balranald_hellfire_pass_to_hellsgate (1080p).mp4',
            caption: 'Hellfire Pass to Hellsgate: A tribute to the hardships and hellish conditions experienced by Australian POW’s labouring on the construction of the Japanese Military’s Thai-Burma Railway during WWII.',
        },
        {
            url: 'https://media.yesterdaystories.net.au/site/4_balranald_the_amiens_gun_delivery (1080p).mp4',
            caption: 'The Amien’s Gun: A poetic tribute to Trooper Smith and WWI battle to capture ‘The Amiens Gun’, a large railway gun firing on the town of Amiens 25 kilometres away.',
        },
        {
            url: 'https://media.yesterdaystories.net.au/site/5_rocco fishing across land.mp4',
            caption: 'Rocco Wollongong Fishing across Lands: No one loves their job more than Rocco! Fishing story set on Wollongong Harbour.',
        },
        {
            url: 'https://media.yesterdaystories.net.au/site/6_Aboriginal Fishtraps, Brewarrina.mp4',
            caption: 'Aborigine Fishtraps, Brewarrina: The Brewarrina Fishtraps are possibly the oldest man-made structures on earth. Built by Aboriginal people thousands of years ago, they have been continuously used as an agricultural resource and a trading and gathering place up until the present day.',
        },
        {
            url: 'https://media.yesterdaystories.net.au/site/7_Cameleers_and_Mosque_V1.mp4',
            caption: 'Broken Hill Mosque/Camaleers: Cameleers hailing from regions all over the world like Bangladesh, Egypt, and Afghanistan, played a pivotal role in Australias exploration and development by using camels to transport goods across the harsh interior landscape. In 1891 they completed the building of a mosque which today is the oldest of its kind in Australia. It stands as a historical monument of their significant contributions and the harsh conditions the cameleers faced.',
        },
        {
            url: 'https://media.yesterdaystories.net.au/site/8_fishers ghost 22.08.mp4',
            caption: 'Camden Fisher’s Ghost: Frederick Fischer was a ticket of leave convict transported to Australia for fraud in the 1800s. He became a successful farmer and cunning business man who was prepared to stab a man over a deal gone sour. Fischer was killed by scheming associate George Wall. His ghost was hence known to haunt the area of Campbelltown where his body was discovered.',
        },
    ];

    return (
        <Carousel
            responsive={responsive}
            containerClass={styles['carousel-container']}
            itemClass={styles['carousel-item-padding']}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}

        >
            {videos.map((video, index) => (
                <div key={index} className={styles['video-wrapper']}>
                    <video className={styles['video-item']} controls>
                        <source src={video.url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <p>{video.caption}</p>
                </div>
            ))}
        </Carousel>
    );
};

export default VideoCarousel;
