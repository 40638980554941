const faqItems = [
    {
        question: 'Do I have to pay for the app?',
        answer: 'Nope, the app is free. We want people to share and know their histories at the community level. : o)'
    },
    {
        question: 'Does it cost to upload stories?',
        answer: 'Nope, to upload is free! We want people to share and show their histories at the community level. We even give you a free ad to your website or organisation if you upload your history video.'
    },
    {
        question: 'Do you take our copyright if we upload stories?',
        answer: 'Nope, your story, your copyright! Our platform is not exclusive and we don’t mind where you share your story. We want people to share and show their histories at the community level.'
    },
    {
        question: 'Do stories have to be documentaries?',
        answer: 'Nope, stories can be animation, drama, photo montage, but they must be factual and historical in nature (no promotional videos for instance).'
    },
    {
        question: 'Do you moderate the stories?',
        answer: 'Yes, each story is viewed before it goes live to ensure it meets our guidelines. Needs to sound and look good for instance, be factual, and be about history. If complaints on stories are made, we have the final decision to take down stories. Well produced stories steeped in research go through!'
    },
    {
        question: 'Who can tell Indigenous stories?',
        answer: 'We follow, and hope you will follow, the Screen Australia protocol about working with Indigenous communities and stories. '
    }
]
export default faqItems;