const firebaseConfig = {
    apiKey: "AIzaSyAhp4sQ9mnNBaEhuWOfke1brPp2QYsazXY",
    authDomain: "yesterday-stories-preprod.firebaseapp.com",
    projectId: "yesterday-stories-preprod",
    storageBucket: "yesterday-stories-preprod.appspot.com",
    messagingSenderId: "727853525935",
    appId: "1:727853525935:web:7657752c9fc80d42f2dd5a",
    measurementId: "G-T149HERJ5B"
};

export default firebaseConfig;


