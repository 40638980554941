import { categoryImages } from '../constants/categories.constants';

const getCategoryImage = (categoryId) => {
    if (!categoryId) {
        return categoryImages[0]
    }

    const id = categoryId - 1;
    const category = id < 0 && id > 13 ? 0 : id;
    return categoryImages[category];
};

export {
    getCategoryImage
};