import React from "react";
import { useState } from "react";

// Below are imported images
import PoliticalRemastered from "./imgs/Political Remastered.png";
import LIghthouse from "./imgs/LIghthouse.jpg";
import QRCode from "./imgs/QR-Code-1.png";
import ParanormalRemastered from "./imgs/Paranormal Remastered.png";
import style from './HomeSectionOne4.module.scss';

import {
  Container,
  Row,
  Col,
  //InputGroup,
  //FormControl,
} from "react-bootstrap";

//video pop up
import VideoThumbnail from "./components/VideoThumbnail";
const VIDEO_LINK = "https://media.yesterdaystories.net.au/prod/ea16a3c3-6037-465d-b608-670115b65131.mp4";
const VIDEO_NAME = "Lighthouse Story";
const VIDEO_DESCRIPTION = "";
const VIDEO_SHARE_LINK = "https://media.yesterdaystories.net.au/pages/312f0e60-f824-11ea-9eed-bd8de8ddac55.html";
const VIDEO_THUMBNAIL = null;

function HomeSectionOne4() {

  const [popupEnabled, setPopupEnabled] = useState(false)

    const handleStoryClick = () => {
      setPopupEnabled(true)
    }

  return (
    <div className="HomeSectionOne4">
      <Container>
        <Row>
            <img
              className={style.politicalRemastered}
              src={PoliticalRemastered}
              alt=""
            />
        </Row>
        <Row className={style.qrcodeSection}>
          <Col xs={7} className="d-flex justify-content-sm-end">
            <div>
              <img onClick={handleStoryClick} className={style.lIghthousesc4} src={LIghthouse} alt="" style={{cursor:"pointer"}}/>
              {popupEnabled && <div className="topHoveringVideo" style={{position: "Absolute", bottom: "0", zIndex:"10000"}}>
                <VideoThumbnail
                closeMapPopup={() => setPopupEnabled(false)}
                borderEnabled={true}
                thumbnailAddress={VIDEO_THUMBNAIL}
                videoAddress={VIDEO_LINK}
                videoName={VIDEO_NAME}
                videoDescription={VIDEO_DESCRIPTION}
                shareLink={VIDEO_SHARE_LINK}
                />
              </div>}
            </div>
          </Col>
          <Col xs={5} className="d-flex justify-content-sm-start flex-column">
            <img className={style.qrCode} src={QRCode} alt="" />
            <h1 className={style.qrText}>
              Scan the QR code<br/> to download the<br/> Yesterday Stories<br/> app now
            </h1>
          </Col>
        </Row>
          <img className={style.paranormalRemastered} src={ParanormalRemastered} alt='' />
      </Container>
    </div>
  );
}
export default HomeSectionOne4;
