import React from "react"
import Style from "./Login.module.scss"
import logo from "../imgs/YS-Logo-01.svg";

function Signup(props) {
    return (
        <>
            <img src={logo} alt="" className={Style.logo} />
            <h3>Sign up</h3><br/>
            <form onSubmit={props.signup} className={Style.login}>

                <input name="email" type="email" placeholder="Email" required/>
                <br/>
                <input name="password" type="password" placeholder="Choose Password" required/>
                <br/>
                <input name="check" type="password" placeholder="Confirm Password" required/>
                <br/>
                
                <div></div>
                <button type="submit" className={Style.button}>Sign Up</button>
                <br/>
                <h6 onClick={props.change} className={Style.option}>Login &#62;</h6>
                <br/>
            </form>
        </>
    );
}
export default Signup;
