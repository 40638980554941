module.exports = [
    {
        feedback: 'I love what you are doing, it’s such a gap in the market, and so authentic.',
        author: 'Business and Investment Attraction Officer (Tourism)'
    },
    {
        feedback: 'I love the app. It’s an amazing way to understand the history of the places we travel to. The stories add to our experience and Ishare it with my children to bring that story to life.',
        author: 'Mel'
    },
    {
        feedback: 'I think the app is awesome! Big fan of it, definitely will be using it a fair bit. I run past the Wollongong Entertainment Centre every day and never knew it was an old burial ground!',
        author: 'Daniel'
    },
    {
        feedback: 'Yesterday Stories is a user-friendly place to find local short histories where past characters and events come to life.Locals and visitors have a new, easy to use, information resource.Yesterday Stories mobile phone app uses a location list and map to allow everyone to “get the good oil” on places and people of interest.The hearing impaired can often read and the sight impaired can hear the stories of the community.I can thoroughly recommend this app as it is becoming a valuable resource for investigating the history of people and things where- ever you happen to be.Both professional and amateur video makers are contributing to Yesterday Stories.I have contributed and so can you.',
        author: 'John Devenish'
    },
    {
        feedback: 'My eight year old son doesn\'t like watching TV and instead prefers streaming YouTube on the TV. The other night I got sick of watching silly meme videos so I cast a wider net with a search and the Yesterday Stories video on Young came up and we watched it together. He got right into it, and has been asking me since when I am taking him to pick some cherries. The Croatian angle in the story was also fascinating as my wife is of Croatian background, so Young is definitely on our list for a short holiday when we can travel again.',
        author: 'MC'
    },
    {
        feedback: 'I have lived here all my life and I had no idea about the history of my area. This is just fantastic',
        author: 'Yesterday stories User'
    },
];