import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import style from './Ourpartners.module.scss'

let firstSponsorSet = []
let secondSponsorSet = []
let allSponsors = []

function Ourpartners() {

  useEffect(() => {
    function importAll(r) {
      return r.keys().map(r);
    }

    allSponsors = importAll(require.context('./imgs/sponsors/', false, /\.(png|jpe?g|svg)$/));
    let middleIndex = Math.floor(allSponsors.length / 2);

    firstSponsorSet = allSponsors.slice(0, middleIndex);
    secondSponsorSet = allSponsors.slice(middleIndex);

  }, [])
  return (
    <div className="divOurpartners" style={{ marginTop: "5vh" }}>
      <Container >
        <Row>
          <h1 className={style.heading}>Our partners</h1>
        </Row>
        <div className={style.logoSlider}>
          <div className={style.logoWrapper1}>
            {firstSponsorSet.map((image, index) => <img key={index} src={image.default} alt="sponsor logo" />)}
          </div>
          <div className={style.logoWrapper1}>
            {firstSponsorSet.map((image, index) => <img key={index} src={image.default} alt="sponsor logo" />)}
          </div>
        </div>
        <div className={style.logoSlider}>
          <div className={style.logoWrapper2}>
            {secondSponsorSet.map((image, index) => <img key={index} src={image.default} alt="sponsor logo" />)}
          </div>
          <div className={style.logoWrapper2}>
            {secondSponsorSet.map((image, index) => <img key={index} src={image.default} alt="sponsor logo" />)}
          </div>
        </div>
      </Container>
    </div>
  );
}
export default Ourpartners;
