import { React, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Container } from "react-bootstrap";
import testimonials from "./constants/testimonials.constants";
import deviceType from './utils/deviceType'
import style from './Testimonial.module.scss';

function Testimonial() {
  const [readFull, setReadFull] = useState(false);
  let isMobile = deviceType();
  const responsive = {
    largeScreen: {
      breakpoint: { max: 3000, min: 1100 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    desktop: {
      breakpoint: { max: 1100, min: 768 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 768, min: 500 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    small: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  const toggleOn = {
    height: isMobile ? '' : '320px',
    borderRadius: '25px',
    fontSize: '1.5vw',
    verticalAlign: 'middle',
    border: '1px solid',
    padding: '10%',
    margin: '5%',
  }
  const toggleOff = {
    height: 'fit-content',
    borderRadius: '25px',
    fontSize: '1.5vw',
    verticalAlign: 'middle',
    border: '1px solid',
    padding: '10%',
    margin: '5%',
  }
  const readMoreStyle = { cursor: 'pointer', textDecoration: 'underline' }
  return (
    <div className="divTestimonial">
      <Container >
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={true}
          responsive={responsive}
          infinite={true}
          keyBoardControl={true}
          customTransition="all .5"
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {testimonials.map((testimonial, index) => {
            let tfb = testimonial.feedback;
            let longText = tfb.match(/(\w+)/g).length > 40;
            return (
              <div key={index}>
                <p style={!readFull ? toggleOn : toggleOff}>
                  <h1 className={style.heading}>Testimonial</h1>
                  <span className={style.content}>{longText && !readFull ? '"' + tfb.split(' ').slice(0, 25).join(' ') + '...'
                    : <span>{'"' + tfb + '"'} <br /><br /><p>{"- " + testimonial.author}</p></span>}</span>
                  <br />
                  <br />
                  <p>{longText && !readFull ?
                    (<span className={style.content} style={readMoreStyle} onClick={() => setReadFull(prevState => !prevState)}>read more</span>) : (<></>)}</p>
                  <p>{longText && readFull ?
                    (<span className={style.content} style={readMoreStyle} onClick={() => setReadFull(prevState => !prevState)}>read less</span>) : (<></>)}</p>
                </p>
              </div>
            )
          })}
        </Carousel>
      </Container>
    </div>
  );
}
export default Testimonial;
