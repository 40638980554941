import React from "react"
import Style from "./Education.module.scss"
import kids from "../imgs/AdobeStock_288098121-website.jpg"
import student1 from "../imgs/U and Me Dave and John 11 RGB Website.jpg" 
import student2 from "../imgs/U and Me Dave and John 32 RGB website.jpg"
import student3 from "../imgs/WHY05027-Website.jpg"
import student4 from "../imgs/WHY00401 website.jpg"
import logoClickView from '../imgs/clickview.jpg'
import cameraStudent from "../imgs/U and Me Dave and John 28 RGB website.jpg"


const Education = () => (
    <div>
        <div className={Style.container}>
            <div>
                <h4 className={Style.heading}>Education</h4>
                <div>
                    <img  src={logoClickView} alt ='Logo-clickView'className={Style.img} />
                    <p className={Style.text}>Yesterday stories has a distribution with Clickview, the number one video storytelling platform linked to curriculum used in 1 in every 2 schools. Teachers, use Clickview and view Yesterday stories with your kids for history. Our stories 3-5 minutes long are perfect and engaging for young people to understand history quickly and succinctly.</p>

                </div>
            </div>
            <img src={kids} alt="education" className={Style.img}/>
     
        
        <div className={Style.section2}>
            <img src={cameraStudent} alt="Camera Student" className={Style.cameraStudent}/>
            <h4 className={Style.heading}>Film-making Workshops</h4>
            <p>Why not book our team to work with your community and young people to produce stories. See some feedback on workshops run through school holidays. </p>
            <button className={Style.emailButton}><a href= "mailto:info@yesterdaystories.com.au?subject=Yesterday stories App"rel="noreferrer noopener" target="_blank">Contact us for more information</a></button>
        </div>

        <div className={Style.container}>
            <img src={student1} alt=""  className={Style.img}/>
            <img src={student2} alt=""  className={Style.img}/>
            <img src={student3} alt=""  className={Style.img}/>
            <img src={student4} alt=""  className={Style.img}/>

        </div>
    </div>
    </div>
)
export default Education;
