import React from "react"
import Article from "./Article"
import article1 from '../imgs/Screenshot-Harpers-Mansion-Berrima-Couple.png'

const Media = () => (
    <div>
    


        <Article 
            id="localHistory" 
            heading="The importance of local history" 
            img={article1} alt="Harper&apos;s Mansion in Berrima" 
            next="#futureGenerations"
            subHeadings={[

                {heading:"", 
                    text:[
                        "If we were to ask you about your local history, how much detail would you be able to give? Wherever you may live, there are lives, stories, emotions and histories that are worth keeping alive.",
                        "As we develop and build, move forward and advance it is crucial we take time to look back and remember how we got here.",
                        'Local history is perhaps some of the most forgotten histories that we have. Nations being founded, wars being won, freedoms, captivities, once in a lifetime events these are what most people would consider "major" and therefore commemorated',
                        'But to a local community major events have and continue to take place and should be remembered.',
                        "What is so important about local history? Why should it matter to you? In case you aren't already convinced, here are a few reasons."
                    ]
                },

                {heading:"1. Local history tells a story", 
                    text:[
                        "Growing up we loved to hear stories about fantasy lands, heroic tales or perhaps hilarious misfortunes. As we aged this childhood innocence faded, but our love for stories didn't need to. ",
                        "Amazing tales of real life people, families, cultures, failures and triumphs are accessible all around us. We don’t have to lose the childhood sense of wonder. In fact, we can thrive knowing the incredible local history of the places around us. You’ll develop a deeper connection, which in turn helps develop a fuller life.",
                        "The mundane around you can shift into a a whole new world as you dive into the history surrounding the community"
                ]
                },

                {heading:"2. The past helps us look forward",
                    text:[
                        "History teaches us a great deal. Everything has a history and taking advantage of that can allow us to learn and make wiser decisions moving forward. No doubt you have heard the saying “those who forget history are destined to repeat it.”",
                        "We live in a time where we can look around, re-live the past, experience the present and plan for the future all in the same day. Learning local history can provide great insight that otherwise would be lacking. ",
                        "History, one of life’s greatest teachers, is all around us, we just have to be willing to learn."
                    ]
                },

                {heading:"3. YOU are local history!", 
                    text:[
                        "You might not realise it, but your choices and life are forging a path. This path once trod by us, will continue on in some way. Those that carry it forward will look back at our lives and take lessons, memories and hopefully some cautions. You’ll eventually become the local history. We each have a choice now, to make it a meaningful one. By learning local history we’ll appreciate our own lives better - plus history can be a lot of fun! ",
                        "We can each gain a greater appreciation of our own lives by remembering the times before us. ",
                        "Loving history can take time, but at the very least embracing local history is a simple and free action we can make which can have profound impacts. History is made by everyone, for everyone and we can dive into the historical depths of the incredible world around us easier than ever.",
                        "Here at Yesterday Stories we know local history is not only important, but we also aim to make it accessible. Download our free app today and discover your important local history!"
                    ]
                },
            ]}
        />
    </div>
)
export default Media;