import React, { useState, useEffect } from 'react';
import style from './ScrollToTop.module.scss'
import upArrow from "../imgs/up-arrow.png";

const ScrollToTop = () => {

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 200) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            {showButton && (
                <button onClick={scrollToTop} className={style.backToTop}>
                    <img src={upArrow} alt="up-arrow" className={style.arrow} />
                </button>
            )}
        </>
    );


}
export default ScrollToTop;