import React from "react"
import { useState } from "react";
import Style from "./Login.module.scss"
import {signOut, getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword} from "firebase/auth";
import { initializeApp } from "firebase/app";
import app from './firebase';
import Login from './Login'
import Signup from "./Signup";
import Admin from "./Admin";
function AuthPage(props) {

    const [newUser, setnewUser] = useState(false);

    const auth = getAuth(initializeApp(app));


    const signMethod = () => {
        setnewUser(!newUser);
        seterror(false);
    }

  
    const handleLogin = async event => {
        event.preventDefault();
        const { email, password } = event.target.elements;
        await signInWithEmailAndPassword(auth, email.value, password.value)
        .then((userCredential) => {
            // Signed in 
            const token = userCredential.user.stsTokenManager.accessToken;
           
           seturl("https://admin.yesterdaystories.net.au/pushLoginAuth.html?token="+token);
           localStorage.setItem('auth', "Autherised to stay logged in");
           props.setUser(true);
           props.setShow("none");
           wellCome();
           seterror(false);
        })
        .catch((error) => {
            if(error.code==="auth/wrong-password"){
                seterror("Invalid Password");
            }
            else if(error.code==="auth/user-not-found"){
                seterror("User Donot exist, please sign up");
            }
            else{
                seterror("An Error occured, please contact support");
            }

        });

    };


    const handleSignup = async event => {
          event.preventDefault();
          const { email, password, check } = event.target.elements;
          if(password.value !== check.value){
            seterror("Passwords do not match");
          }
          else{
            
            await createUserWithEmailAndPassword(auth, email.value, password.value)
            .then((userCredential) => {
                // Signed in 
                const token = userCredential.user.stsTokenManager.accessToken;
               
                wellCome();
                seturl("https://admin.yesterdaystories.net.au/pushLoginAuth.html?token="+token);
                localStorage.setItem('auth', "Autherised to stay logged in");
                props.setUser(true);
                props.setShow("none");
                seterror(false);
            })
            .catch((error) => {
                console.log(error.code);
                if(error.code==="auth/email-already-in-use"){
                    seterror("Email allready used");
                }
                else if(error.code==="auth/weak-password"){
                    seterror("Weak Password");
                }
                else{
                    seterror("An Error occured, please contact support");
                }
            });
          }
          
        };


        async function signout(){
            await signOut(auth).then(() => {
                // Sign-out successful.
                seturl("https://admin.yesterdaystories.net.au/pushLoginAuth.html?token=signout");
                localStorage.clear();
                props.setUser(false);
              }).catch((error) => {
                // An error happened.
              });
              
        }

        const userArea = () =>{
            if (newUser){
                return <Signup signOut={signout} signup={handleSignup} change={signMethod}/>;
            }

            return <Login signOut={signout} login={handleLogin} change={signMethod}/>;
        }
        
        //set token at admin site by url
        const [url, seturl] = useState("about:blank");

        const [error, seterror] = useState(false);

        const [wellcome, setwellcome] = useState({display: "none"});

        function wellCome() {
            setwellcome({display: "block"});
            setTimeout(()=>setwellcome({display: "none"}), 7000);
        }

        let display = {display: props.show}



    return (
        <>
            {props.admin && <Admin setAdmin={props.setAdmin} />}
            
            <div className={Style.wellcome} style={wellcome}>
                <h3>Wellcome to Yesterday Stories <span style={{color:"green"}}>&#10687;</span></h3>
            </div>
            <div className={Style.background} style={display}>
                <div className={Style.card}>
                    <div className={Style.close} onClick={()=>props.setShow('none')}>&nbsp;&nbsp;X&nbsp;&nbsp;</div>
                    <br/>
                    {error && <div className={Style.error}>{error}</div>}
                    {props.user ?
                        <button className={Style.button} onClick={signout}>Signout</button> : userArea()
                    }

                    <br/>
                </div>
                <iframe src={url} title="hidden consumer" style={{display:"none"}}></iframe>
                
            </div>
        </>
    );
}
export default AuthPage;