import React from 'react';
import { useState } from 'react';

// Below are imported images
import style from './HomeSectionOne2.module.scss';

import OurCommunitiesRemastered from './imgs/OurCommunitiesRemastered.png';
import IndustryRemastered from './imgs/IndustryRemastered.png';
import SitesLandmarksRemaster from './imgs/Sites _ Landmarks Remaster.png';
import Filmmaker from './imgs/Filmmaker.png';


import MajorEventsRemastered from './imgs/MajorEventsRemastered.png';
import ColonialRemastered from './imgs/ColonialRemastered.png';
import indigenous_Remastered from './imgs/indigenous Remastered.png';

import Haprers from './imgs/Harpers-Mansion-Berrima-Cafe.png';
import MuesumsGalleries from './imgs/MuesumsGalleries.png';
import ALLStoriesRemastered from './imgs/ALLStoriesRemastered.png';
//Imported images finished
// import './style.css';
import { Container, Row, Col } from 'react-bootstrap';
import deviceType from './utils/deviceType';

//video pop up
import VideoThumbnail from "./components/VideoThumbnail";


const VIDEO_LINK = "https://media.yesterdaystories.net.au/prod/509d866b-dc04-452b-af5a-12c0531c18fc.mp4";
const VIDEO_NAME = "Harpers Mansion";
const VIDEO_DESCRIPTION = "";
const VIDEO_SHARE_LINK = "https://media.yesterdaystories.net.au/pages/14eca930-4e26-11eb-8805-a3c6301e1ea1.html";
const VIDEO_THUMBNAIL = null;

function HomeSectionOne2({user, setShow, setAdmin}) {
    let isMobile = deviceType();

    const [popupEnabled, setPopupEnabled] = useState(false);


    const handleStoryClick = () => {
      setPopupEnabled(true)
    }
    const handleUploadClick = () => {
        if (user) {
          setAdmin(true); 
        } else {
          setShow("flex"); 
        }
      };


    return (
        <>
            <div className='HomeSectionOne2'>
                <Container>
                    <img className={style.ourCommunitiesRemastered} src={OurCommunitiesRemastered} alt='' />
                    <img className={style.industryRemasteredBig} src={IndustryRemastered} alt='' />
                    <img className={style.sitesLandmarksRemaster} src={SitesLandmarksRemaster} alt='' />
                    <img className={style.majorEventsRemastered} src={MajorEventsRemastered} alt=' ' />
                    <img className={style.colonialRemasteredBig} src={ColonialRemastered} alt='' />
                    <img className={style.allStoriesRemastered} src={ALLStoriesRemastered} alt='' />
                    <img style={isMobile ? { display: 'none' } : {}} className={style.colonialRemasteredSmall} src={ColonialRemastered}
                        alt='' />
                    <img className={style.indigenousRemasteredSmall} src={indigenous_Remastered} alt=''
                        style={isMobile ? { display: 'none' } : {}} />
                    <img className={style.imgSitesLandmarksRemaster} src={SitesLandmarksRemaster} alt=''
                        style={isMobile ? { display: 'none' } : {}} />
                    <img className={style.museumsGalleriesSmall} src={MuesumsGalleries} alt=''
                        style={isMobile ? { display: 'none' } : {}} />
                    <img style={isMobile ? { display: 'none' } : {}} className={style.industryRemasteredSmall}
                        src={IndustryRemastered} alt='' />
                    <Row>
                        <Col lg={12} sm={12} style={isMobile ? {} : { padding: '0 6.2rem' }}>
                            <br /><br />
                            <h3 className={style.mainText}>Share and watch short film <br />stories about the local history {isMobile ? "": <br />} and
                                people of the areas you visit, {isMobile ? "": <br />} on the Yesterday Stories app.</h3>

                        </Col>
                    </Row>
                    <Row style={{ marginTop: '5vh' }}>
                        
                        <Col lg={7} md={6} xs={7} className='d-flex justify-content-end'>
                            <img className={style.filmMaker} src={Filmmaker} alt='' />
                        </Col>
                        <Col lg={5} md={6} xs={5} className='d-flex justify-content-sm-start'>
                            <div  className={style.uploadContainer}>
                                <p className={style.subTitle1}>Upload your own film and link it to location in the app</p>
                                <button 
                                    className={style.uploadButton} 
                                    onClick={handleUploadClick}
                                >
                                        Upload now
                                </button>
                            </div>  
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={5} className='d-flex justify-content-sm-end' style={{ justifyContent: 'flex-end' }}>
                            <div  className={style.uploadTrail}>
                                <p className={style.subTitle2}>Create your own trail upload 5 videos and make your trail $3.99 month</p>
                                <button 
                                    className={style.uploadButtonTrail}
                                    onClick={handleUploadClick}
                                >
                                    Pay now
                                </button>
                            </div>
                        
                        </Col>
                        <Col md={6} xs={7} className='d-flex justify-content-sm-start'>
                            <div>
                                <img onClick={handleStoryClick} className={style.haprers} src={Haprers} alt='' style={{cursor:"pointer"}} />
                                <div className="topHoveringVideo" style={{position: "Absolute", bottom: "0"}}>
                                    {popupEnabled && <VideoThumbnail
                                    closeMapPopup={() => setPopupEnabled(false)}
                                    borderEnabled={true}
                                    thumbnailAddress={VIDEO_THUMBNAIL}
                                    videoAddress={VIDEO_LINK}
                                    videoName={VIDEO_NAME}
                                    videoDescription={VIDEO_DESCRIPTION}
                                    shareLink={VIDEO_SHARE_LINK}
                                    />}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default HomeSectionOne2;
